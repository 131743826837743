
// icons
import { notifications, pin, star, heart, heartOutline, } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonChip, IonBadge, IonIcon, IonThumbnail, IonAvatar, IonImg, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "place",
    "metric",
  ],
  components: { IonItem, IonLabel, IonChip, IonBadge, IonIcon, IonThumbnail, IonAvatar, IonImg, },
  setup() {
    const { t } = useI18n();
    const { formatDate, getTypeColor, numberWithCommas, } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      notifications, pin, star, heart, heartOutline,

      // methods
      t, formatDate, getTypeColor, numberWithCommas,
    }
  }
}
