
// Vue reactivity
import { computed, ref } from "vue";

// components
import { IonContent, IonPage, IonList, IonGrid, IonRow, IonCol, IonSpinner, IonButtons, IonBackButton } from "@ionic/vue";
import PlaceItem from "@/components/PlaceItem.vue";

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: "UserLikedPlaceListPage",
  components: {
    IonContent, IonPage, IonList, IonGrid, IonRow, IonCol, IonSpinner, IonButtons, IonBackButton,
    PlaceItem,
  },
  setup() {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();
    const loading = computed(() => store.state.loadingAppPublicData);
    const places = computed(() => store.getters.userLikedPlaces);

    // methods or filters
    const { t } = useI18n();

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // state variables
      loading, places,
    };
  },
};
