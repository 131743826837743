import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"5px"} }
const _hoisted_2 = {
  key: 0,
  class: "ion-text-nowrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    "router-link": {
      name: 'PlaceDetailPage',
      params: { id: $props.place.id, }
    },
    button: "",
    detail: ""
  }, {
    default: _withCtx(() => [
      ($props.place.mainImgLink)
        ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
            key: 0,
            slot: "start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_img, {
                src: $props.place.mainImgLink
              }, null, 8, ["src"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, _toDisplayString($props.place["名稱"]), 1),
          _createVNode(_component_ion_badge, {
            color: $setup.getTypeColor($props.place['種類'])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.place['種類']), 1)
            ]),
            _: 1
          }, 8, ["color"]),
          _createTextVNode("  "),
          _createVNode(_component_ion_badge, { color: "tertiary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.place["地區"]), 1)
            ]),
            _: 1
          }),
          _createTextVNode("  "),
          ($props.metric && $props.metric.key)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.metric.name) + "：" + _toDisplayString($props.place[$props.metric.key] ? $setup.numberWithCommas($props.place[$props.metric.key]) : "-"), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link"]))
}